import React, { useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Container, Typography, Stack, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import dataProvider from '../dataProvider';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import RegisterLayoutWithoutHeader from '../common_modules/components/RegisterLayoutWithoutHeader';

const Verification: React.FC = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const [hasError, setHasError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ecSiteName, setEcSiteName] = useState<string | undefined>(undefined);
  const [ecSiteImage, setEcSiteImage] = useState<string | undefined>(undefined);

  const requestSentRef = useRef(false);

  useEffect(() => {
    setLoading(true);
    let ignore = false;
    async function fetchToken() {
      try {
        if (requestSentRef.current) return;
        requestSentRef.current = true;

        if (!ignore) {
          const token = new URLSearchParams(location.search).get('t');
          const requestBody = {
            token
          };
          const response = await dataProvider.register(requestBody, '/registers/partners/verification');
          if (response.data && response.data.ecSiteName) {
            setEcSiteName(response.data.ecSiteName);
            if (response.data.storeLogoThumbnail) {
              setEcSiteImage(response.data.storeLogoThumbnail.src);
            }
          } else {
            navigate('/register/partner/error');
          }
        }
      } catch (e: any) {
        const errorMessage = e.message;
        setHasError(errorMessage);
      }

      setLoading(false);
    }

    fetchToken();

    return () => {
      ignore = true;
    };
  }, []);

  const Layout = hasError ? RegisterLayoutWithoutHeader : RegisterLayout;

  return (
    <Layout inviteSite={ecSiteName}>
      {!hasError ? (
        <Container
          sx={{ 
            maxWidth: { sm: '552px', md: '772px' },
            marginTop: { xs: '20px', sm: '40px'},
            marginBottom: { xs: '20px', sm: '40px'},
            borderRadius: { sm: '24px', md: '24px' }, 
            border: { sm: '1px solid #EEEEEE', md: '1px solid #EEEEEE' },
            backgroundColor: '#FFFFFF'
        }}
        >
        <Helmet>
          <title>{translate('resources.registers.name.ReviewApplicationCompleted')}</title>
        </Helmet>

        <Stack 
          spacing={1}
          sx={{ 
            paddingLeft: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingRight: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingTop: { sm: '40px', md: '64px' },
            paddingBottom: { sm: '40px', md: '64px' }
          }}
        >
         
          <RegisterHeaderProgress
            title={translate('resources.registers.name.ReviewApplicationCompleted')}
            icon={<CheckCircleOutlineOutlinedIcon />}
            step={7}
            totalSteps={7}
          />

          <Box display="flex" justifyContent="center">
            <Box 
              sx={{
                width: { xs: '288px', sm: '400px' },
                height: '20px',
              }}
              component="img" 
              src={`/images/stepper/img_stepper_7.svg`} 
              alt="stepper_7" 
            />
          </Box>
        
          <Typography>
            申請者の本人確認が完了しました。
            <br />
            パートナー登録のための審査を行います。この審査は通常3~5営業日で完了します。
            <br />
            審査終了後はご登録のメールアドレスに審査結果のメールが送られます。
          </Typography>  
        </Stack>
      </Container>
      ) : (
        <Container>
          <Box display="flex" alignItems="center" sx={{ padding: 2 }}>
            <ErrorOutlineIcon sx={{ marginRight: 1 }} />
            <Typography variant="h5" align="left">
              {hasError}
            </Typography>
          </Box>
        </Container>
      )}
    </Layout>
  );
};

export default Verification;
