import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  alpha,
  Stack,
  useMediaQuery,
  styled,
  Grid,
  Paper
} from '@mui/material';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { BANK_ACCOUNT_TYPE, PARTNER_TYPE, getChoiceName } from '../common_modules/constants/choices';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import dataProvider from '../dataProvider';
import { getRegisterFormData } from '../common_modules/components/Common';
import { Helmet } from 'react-helmet';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import LinkButton from '../common_modules/components/LinkButton';
import WrappedCheckBoxFormControlLabel from '../common_modules/components/WrappedCheckBoxFormControlLabel';
import AspectRatioBox from '../common_modules/components/AspectRatioBox';

const BorderStack = styled(Stack)(({}) => ({
  padding: '24px',
  borderColor: alpha('#000000', 0.5),
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '4px'
}));

const Confirmation: React.FC = () => {
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState<any>({});
  const [frontImage, setFrontImage] = React.useState<string>('');
  const [backImage, setBackImage] = React.useState<string>('');

  const handleError = useErrorHandler();
  const translate = useTranslate();
  const [reCaptchaToken, setReCaptchaToken] = React.useState<string>('');

  const { executeRecaptcha } = useGoogleReCaptcha();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsConfirmed(false);
  }, []);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('confirmation');
    setReCaptchaToken(token);
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const fetchData = useCallback(async () => {
    try {
      const formData = getRegisterFormData() as any;

      const token = '';
      const url = `/registers/partners/download?token=${formData.token}`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;
      setFrontImage(data.frontImage.src);
      setBackImage(data.backImage.src);
    } catch (error) {}
  }, [dataProvider]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const formData = getRegisterFormData();
    if (formData) {
      setFormData(formData);
    } else {
      navigate(`/register/partner/error`);
    }
  }, [navigate]);

  const handleSubmit = async () => {
    try {
      let sendingFormData = getRegisterFormData() as any;
      if (!sendingFormData) {
        navigate(`/register/partner/error`);
        return;
      }
      sendingFormData.reCaptchaToken = reCaptchaToken;

      const registerPartnerUrl = `/registers/partners/create`;
      setIsLoading(true);
      await dataProvider.register(sendingFormData, registerPartnerUrl);
      // 登録完了ページへ
      navigate(`/register/partner/finish`);
    } catch (error) {
      handleError();
      handleReCaptchaVerify();
    }
    setIsLoading(false);
  };

  const handleConfirmChange = () => {
    setIsConfirmed(!isConfirmed);
  };

  const renderField = (label: string, value: any) => (
    <Box>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );

  return (
    <RegisterLayout>
      <Container
        sx={{ 
          maxWidth: { sm: '552px', md: '772px' },
          marginTop: { xs: '20px', sm: '40px'},
          marginBottom: { xs: '20px', sm: '40px'},
          borderRadius: { sm: '24px', md: '24px' }, 
          border: { sm: '1px solid #EEEEEE', md: '1px solid #EEEEEE' },
          backgroundColor: '#FFFFFF'
        }}
      >
        <Helmet>
          <title>{translate('resources.registers.name.ConfirmRegistrationDetails')}</title>
        </Helmet>
        <Stack 
          spacing={1}
          sx={{ 
            paddingLeft: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingRight: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingTop: { sm: '40px', md: '64px' },
            paddingBottom: { sm: '40px', md: '64px' }
          }}
        >
          <RegisterHeaderProgress
            title={translate('resources.registers.name.ConfirmRegistrationDetails')}
            icon={<ChecklistOutlinedIcon />}
            step={4}
            totalSteps={7}
          />

          <Box display="flex" justifyContent="center">
            <Box 
              sx={{
                width: { xs: '288px', sm: '400px' },
                height: '20px',
              }}
              component="img" 
              src={`/images/stepper/img_stepper_5.svg`} 
              alt="stepper_5" 
            />
          </Box>

          <Stack spacing={isSmallScreen ? 3 : 5}>
            <Stack spacing={3}>
              
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box
                    sx={{
                      width: '4px',   
                      height: '32px',
                      backgroundColor: '#5B6955'
                    }}
                  />
                  <Typography 
                    sx={{ fontSize: { xs: '25px', sm: '34px' } }}
                  >
                    基本情報
                  </Typography>
                </Stack>
                
                <Button 
                  sx={{
                    border: '2px solid #5B6955',
                    '&:hover': {
                      border: '2px solid #5B6955'
                    }
                  }}
                  variant="outlined"
                  onClick={() => navigate('/register/partner/form?redirect-from-confirm=true')}
                >
                  基本情報を修正
                </Button>
              </Stack>

              <BorderStack 
                spacing={2}
                sx={{
                  border: '2px solid #000000',
                  borderColor: 'rgba(0, 0, 0, 0.3)'
                }}
              >
                {formData.partnerType === 2 && renderField(translate('resources.registers.fields.name'), formData.name)}
                {renderField(
                  translate(
                    formData.partnerType === 2
                      ? 'resources.registers.fields.picLastName'
                      : 'resources.registers.fields.lastName'
                  ),
                  formData.lastName
                )}
                {renderField(
                  translate(
                    formData.partnerType === 2
                      ? 'resources.registers.fields.picFirstName'
                      : 'resources.registers.fields.firstName'
                  ),
                  formData.firstName
                )}
                {renderField(translate('resources.registers.fields.zipCode'), formData.zipCode)}
                {renderField(translate('resources.registers.fields.prefecture'), formData.prefecture)}
                {renderField(translate('resources.registers.fields.city'), formData.city)}
                {renderField(translate('resources.registers.fields.address1'), formData.address1)}
                {renderField(translate('resources.registers.fields.address2Updated'), formData.address2)}
                {formData.partnerType === 2 && renderField(translate('resources.registers.fields.tel'), formData.tel)}
                {formData.partnerType === 1 &&
                  renderField(translate('resources.registers.fields.mobilePhone'), formData.mobilePhone)}

                {renderField(translate('resources.registers.fields.email'), formData.email)}
                {renderField(translate('resources.registers.fields.emailConfirm'), formData.emailConfirm)}
                {renderField(translate('resources.registers.fields.websiteUrl'), formData.website)}
                {renderField(translate('resources.registers.fields.invoiceNumber'), formData.invoiceNumber)}
                {formData.partnerType === 2 &&
                  renderField(translate('resources.registers.fields.dunsNumber'), formData.dunsNumber)}

                {/* {renderField(translate('resources.registers.fields.bankNumber'), formData.bankNumber)}
                {renderField(translate('resources.registers.fields.bankName'), formData.bankName)}
                {renderField(translate('resources.registers.fields.bankBranchNumber'), formData.bankBranchNumber)}
                {renderField(translate('resources.registers.fields.bankBranchName'), formData.bankBranchName)}
                {renderField(
                  translate('resources.registers.fields.bankAccountType'),
                  getChoiceName(BANK_ACCOUNT_TYPE, formData.bankAccountType)
                )}
                {renderField(translate('resources.registers.fields.bankAccountNumber'), formData.bankAccountNumber)}
                {renderField(translate('resources.registers.fields.bankAccountName'), formData.bankAccountName)} */}
              </BorderStack>
            </Stack>

            {/* {formData.partnerType === 1 && (
              <Stack spacing={3}>
                <Typography variant={'h5'}>身分証明書</Typography>
                <BorderStack spacing={3}>
                  <Box sx={{ p: 0 }}>
                    <Grid container spacing={2} sx={{ m: 0, width: '100%' }}>
                      <Grid xs={12} md={6}>
                        <Typography variant="caption" color="textSecondary">
                          表面
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <AspectRatioBox ratio={400 / 254}>
                            <Box
                              component="img"
                              src={frontImage}
                              alt={'表面'}
                              sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                              }}
                            />
                          </AspectRatioBox>
                        </Box>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography variant="caption" color="textSecondary" gutterBottom>
                          裏面
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <AspectRatioBox ratio={400 / 254}>
                            <Box
                              component="img"
                              src={backImage}
                              alt={'表面'}
                              sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                              }}
                            />
                          </AspectRatioBox>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </BorderStack>
              </Stack>
            )} */}

            <Stack spacing={isSmallScreen ? 3 : 5}>
              <Stack spacing={3}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box
                      sx={{
                        width: '4px',   
                        height: '32px',
                        backgroundColor: '#5B6955'
                      }}
                    />
                    <Typography 
                      sx={{ fontSize: { xs: '25px', sm: '34px' } }}
                    >
                      口座情報
                    </Typography>
                  </Stack>
                  
                  <Button 
                    sx={{
                      border: '2px solid #5B6955',
                      '&:hover': {
                        border: '2px solid #5B6955'
                      }
                    }}
                    variant="outlined"
                    onClick={() => navigate('/register/partner/form-payment?redirect-from-confirm=true')}
                  >
                    口座情報を修正
                  </Button>
                </Stack>
                
                <BorderStack 
                  spacing={2}
                  sx={{
                    border: '2px solid #000000',
                    borderColor: 'rgba(0, 0, 0, 0.3)'
                  }}
                >
                  {renderField(translate('resources.registers.fields.bankNumberUpdated'), formData.bankNumber)}
                  {renderField(translate('resources.registers.fields.bankName'), formData.bankName)}
                  {renderField(translate('resources.registers.fields.bankBranchNumberUpdated'), formData.bankBranchNumber)}
                  {renderField(translate('resources.registers.fields.bankBranchName'), formData.bankBranchName)}
                  {renderField(
                    translate('resources.registers.fields.bankAccountType'),
                    getChoiceName(BANK_ACCOUNT_TYPE, formData.bankAccountType)
                  )}
                  {renderField(translate('resources.registers.fields.bankAccountNumber'), formData.bankAccountNumber)}
                  {renderField(translate('resources.registers.fields.bankAccountName'), formData.bankAccountName)}
                </BorderStack>
              </Stack>
            </Stack>

            <WrappedCheckBoxFormControlLabel
              control={<Checkbox checked={isConfirmed} onChange={handleConfirmChange} />}
              label="入力内容に間違いがないことを確認しました。"
            />
          </Stack>

          <Stack 
            alignItems="center" 
            spacing={3}
            sx={{
              marginTop: '24px'
            }}
          >
            <RegisterSubmitButton
              onClick={handleSubmit}
              disabled={!isConfirmed || isLoading || reCaptchaToken.length === 0}
            >
              本人確認へ
            </RegisterSubmitButton>
            {/* <LinkButton href="/register/partner/form">{'< 登録情報入力に戻る'}</LinkButton> */}
            {/* {formData.partnerType === 1 ? (
              <LinkButton href="/register/partner/upload-id">{'< 身分証明書選択に戻る'}</LinkButton>
            ) : (
              <LinkButton href="/register/partner/form">{'< 登録情報入力に戻る'}</LinkButton>
            )} */}
          </Stack>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default Confirmation;
