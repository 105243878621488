import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import OutboxIcon from '@mui/icons-material/Outbox';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography, Tooltip } from '@mui/material';
import {
  Create,
  CreateButton,
  ImageField,
  List,
  SaveButton,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Toolbar,
  TopToolbar,
  defaultExporter,
  required,
  useTranslate,
  useNotify,
  useRedirect,
  useDataProvider,
  FunctionField,
  useRecordContext
} from 'react-admin';
import EnumTextField from '../common_modules/components/EnumTextField';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import PercentNumberField from '../common_modules/components/PercentNumberField';
import { getLocalStorageId } from '../authProvider';
import dataProvider from '../dataProvider';
import { useChangedIdListCount } from '../common_modules/hooks/ChangedIdListCount';

const CustomCreateButton = () => <CreateButton label="resources.affiliates.actions.add" icon={<AddIcon />} />;

const ListActions = () => (
  <TopToolbar>
    <CustomCreateButton />
  </TopToolbar>
);

const CustomToolbar = () => (
  <Toolbar>
    <SaveButton label="申請" icon={<OutboxIcon />} />
  </Toolbar>
);

const CouponOrUrlField = () => {
  const record = useRecordContext(); // ✅ record を取得
  const ecSiteType = record?.ecSiteType; // ✅ `record.clientId` を参照

  // ✅ `ecSiteType` による表示の分岐
  return ecSiteType === 3 ? (
    <Box mb={2}>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        クーポンコード
      </Typography>
      <Box display="flex" alignItems="center">
        <TextField source="id" />
      </Box>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        ECサイトURL
      </Typography>
      <Box display="flex" alignItems="center">
        <FunctionField
          source="ecSiteUrl"
          render={(record: any) => {
            const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              const userConfirmed = window.confirm('新しいタブでこのリンクを開きますか？');
              if (userConfirmed) {
                window.open(record.ecSiteUrl, '_blank', 'noopener,noreferrer');
              }
            };

            return (
              <Tooltip title={record.ecSiteUrl}>
                <Typography
                  component="a"
                  href={record.ecSiteUrl}
                  onClick={handleClick}
                  sx={{
                    fontSize: '14px',
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'none' }
                  }}
                >
                  {record.ecSiteUrl}
                </Typography>
              </Tooltip>
            );
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        アフィリエイトURL
      </Typography>
      <Box display="flex" alignItems="center">
        <FunctionField
          source="affiliateUrl"
          render={(record: any) => {
            const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              const userConfirmed = window.confirm('新しいタブでこのリンクを開きますか？');
              if (userConfirmed) {
                window.open(record.affiliateUrl, '_blank', 'noopener,noreferrer');
              }
            };

            return (
              <Tooltip title={record.affiliateUrl}>
                <Typography
                  component="a"
                  href={record.affiliateUrl}
                  onClick={handleClick}
                  sx={{
                    fontSize: '14px',
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'none' }
                  }}
                >
                  {record.affiliateUrl}
                </Typography>
              </Tooltip>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export const AffiliatesList = () => {
  const translate = useTranslate();
  const notify = useNotify();

  const rowStyle = (record: any) => ({
    opacity: record.invalidFlag === 0 ? 0.5 : 1,
    backgroundColor: record.invalidFlag === 0 ? 'lightgray' : 'inherit'
  });

  return (
    <List
      resource="affiliates"
      actions={<ListActions />}
      exporter={defaultExporter}
      sort={{ field: 'applyStatus', order: 'ASC' }}
    >
      <FlexibleListLayout
        rowClick={(id, basePath, record) => {
          if (!record.id || record.invalidFlag === 0) {
            notify('未承認または却下、または無効の項目は表示できません。', { type: 'error' });
            return false;
          } else {
            return 'show';
          }
        }}
        bulkActionButtons={false}
        rowSx={rowStyle}
      >
        <EnumTextField
          source="applyStatus"
          label={translate('resources.affiliates.fields.applyStatus')}
          translationKeyPrefix="values.apply-status"
        />
        <ReferenceField source="clientId" reference="clients" label={translate('resources.affiliates.fields.client')}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" label={translate('resources.affiliates.fields.id')} />

        <FunctionField
          label={translate('resources.affiliates.fields.affiliateUrl')}
          render={(record: any) => {
            const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              const userConfirmed = window.confirm('新しいタブでこのリンクを開きますか？');
              if (userConfirmed) {
                window.open(record.affiliateUrl, '_blank', 'noopener,noreferrer');
              }
            };

            // ✅ `ecSiteType === 3` の場合は URL を非表示
            if (record.ecSiteType === 3) {
              return null; // ❗️ `null` を返して非表示
            }

            return record.invalidFlag === 0 ? (
              <span>{record.affiliateUrl}</span>
            ) : (
              <Tooltip title={record.affiliateUrl}>
                <Typography
                  component="a"
                  href={record.affiliateUrl}
                  onClick={handleClick}
                  sx={{
                    fontSize: '14px',
                    display: 'inline-block',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'normal',
                    textOverflow: 'ellipsis',
                    color: 'primary.main',
                    textDecoration: 'underline',
                    '&:hover': { textDecoration: 'none' },
                    wordBreak: 'break-all' // 長いURLが枠内に収まるように追加
                  }}
                >
                  {record.affiliateUrl}
                </Typography>
              </Tooltip>
            );
          }}
        />

        <FunctionField
          label={false}
          render={(record: any) =>
            record.invalidFlag === 0 ? (
              <Box display="flex" alignItems="center">
                <Tooltip
                  title={
                    <Box
                      sx={{
                        color: 'red',
                        backgroundColor: 'white'
                      }}
                    >
                      ECサイトにより無効にされました。
                      <br />
                      該当ECサイトにご確認ください。
                    </Box>
                  }
                  PopperProps={{
                    sx: {
                      '.MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        border: '1px solid red'
                      }
                    }
                  }}
                >
                  <WarningIcon sx={{ color: 'red' }} />
                </Tooltip>
              </Box>
            ) : null
          }
        />
      </FlexibleListLayout>
    </List>
  );
};
export default AffiliatesList;

const GetChangedIdListCount = () => {
  const record = useRecordContext();
  const { setChangedIdListCount } = useChangedIdListCount();

  React.useEffect(() => {
    if (record) {
      try {
        const parsedUrl = new URL(record.affiliateUrl);
        const siteParam = parsedUrl.searchParams.get('site');
        const decodedSiteParam = siteParam ? decodeURIComponent(siteParam) : '';
        const formattedUrl = decodedSiteParam;

        const currentItems = JSON.parse(localStorage.getItem('currentItems') || '[]');
        const updatedItems = currentItems.map((item: { clientId: number; url: string }) => {
          if (item.clientId === record.clientId) {
            return { ...item, url: formattedUrl };
          }
          return item;
        });
        localStorage.setItem('currentItems', JSON.stringify(updatedItems));

        // 更新後に非同期処理を行う関数を定義
        const fetchData = async () => {
          try {
            const savedItems = localStorage.getItem('currentItems');
            let items;

            if (savedItems) {
              items = JSON.parse(savedItems);
            } else {
              items = [
                {
                  clientId: 0,
                  url: 'https://example.com'
                }
              ];
            }

            const response = await dataProvider.customPost('affiliate-urls/count', { items });

            if (response && response.data && response.data.currentItems) {
              localStorage.setItem('currentItems', JSON.stringify(response.data.currentItems));
            }

            const changedIdList = response?.data?.changedIdList?.length ?? 0;
            if (changedIdList > 0) {
              setChangedIdListCount(changedIdList);
            } else {
              setChangedIdListCount(0);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        // fetchDataを呼び出す
        fetchData();
      } catch (error) {
        console.error('Error parsing affiliateUrl:', error);
      }
    }
  }, [record, setChangedIdListCount]);

  // 何も表示しない
  return null;
};

export const AffiliateShow = () => {
  const translate = useTranslate();

  return (
    <Show resource="affiliates" title="PR用ツール">
      <SimpleShowLayout>
        <GetChangedIdListCount />
        <EnumTextField source="applyStatus" translationKeyPrefix="values.apply-status" />
        <ReferenceField source="clientId" reference="clients" label={translate('resources.affiliates.fields.client')}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" />

        {/* ✅ `ecSiteType === 3` の場合は クーポンコード表示 */}
        <CouponOrUrlField />

        <PercentNumberField source="partnerReward" />

        {/* ✅ `ecSiteType === 3` の場合は `qrCode` を非表示 */}
        <FunctionField
          label={false}
          render={(record) => (record.ecSiteType !== 3 ? <ImageField source="qrCode" /> : null)}
        />

        {/* 無効な場合は警告アイコンを表示 */}
        <FunctionField
          label={false}
          render={(record) =>
            record.invalidFlag === 0 ? (
              <Box display="flex" alignItems="center">
                <Tooltip
                  title={
                    <Box sx={{ color: 'red', backgroundColor: 'white' }}>
                      ECサイトにより無効にされました。
                      <br />
                      該当ECサイトにご確認ください。
                    </Box>
                  }
                  PopperProps={{
                    sx: { '.MuiTooltip-tooltip': { backgroundColor: 'white', border: '1px solid red' } }
                  }}
                >
                  <WarningIcon sx={{ color: 'red' }} />
                </Tooltip>
              </Box>
            ) : null
          }
        />
      </SimpleShowLayout>
    </Show>
  );
};

export const AffiliateCreate = () => {
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const handleSubmit = async (data: any) => {
    try {
      // 'name'フィールドの値を'clientId'に変換してAPIに送信
      const transformedData = {
        clientId: data.clientId,
        partnerId: parseInt(getLocalStorageId() ?? '0')
      };
      await dataProvider.create('affiliates', { data: transformedData });
      notify('申請しました。', { type: 'success' });
      redirect('list', '/affiliates');
    } catch (error) {
      handleError();
      return (error as any).body.errors;
    }
  };

  return (
    <Create title="ECサイト追加" redirect="list" mutationOptions={{ onError: handleError }}>
      <Typography sx={{ margin: '1em 0 0 1em' }}>
        アフィリエイトを申請したいECサイトを選択して、申請ボタンを押してください。
        <br />
        承認されるとアフィリエイトリンクが生成されて通知が届きます。
      </Typography>
      <SimpleForm onSubmit={handleSubmit} toolbar={<CustomToolbar />} sx={{ maxWidth: '600px' }}>
        <ReferenceInput source="clientId" reference="clients" sort={{ field: 'name', order: 'ASC' }} perPage={300}>
          <SelectInput
            optionText="name"
            label={translate('resources.affiliates.fields.client')}
            validate={required()}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
