import React from 'react';
import { useEffect, useState } from 'react';
import {
  CreateBase,
  CreateButton,
  List,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  required,
  useNotify,
  useTranslate,
  Title
} from 'react-admin';
import { Box, Typography, alpha, Stack, styled } from '@mui/material';
import EnumTextField from '../common_modules/components/EnumTextField';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import FileUploadInput from '../common_modules/components/FileUploadInput';
import dataProvider from '../dataProvider';
import { getLocalStorageId } from '../authProvider';
import { useFormContext } from 'react-hook-form';
import PercentNumberField from '../common_modules/components/PercentNumberField';
import PercentNumberInput from '../common_modules/components/PercentNumberInput';
import FormValidation from '../common_modules/components/FormValidation';
import SendIcon from '@mui/icons-material/Send';

const CustomCreateButton = () => <CreateButton label="新規パートナーを招待" icon={<PersonAddAlt1Icon />} />;
const ListActions = () => (
  <TopToolbar>
    <CustomCreateButton />
  </TopToolbar>
);

export const InvitationList = () => {
  const translate = useTranslate();
  return (
    <List 
      sx={{
        '& .MuiPaper-root': {
          boxShadow: { 
            xs: 'none', 
            sm: 'none', 
            md: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'}
          },
          '& .RaList-actions': {
            display: { xs: 'none', sm: 'none', md: 'flex' }
          }
      }}
      actions={<ListActions />}
    >
      <FlexibleListLayout 
        bulkActionButtons={false}
        sx={{
          '& .MuiTableCell-root': { borderBottom: 'none' }
        }}
      >
        <TextField source="inviteName" />
        <TextField source="inviteEmail" />
        <PercentNumberField source="partnerReward" />
        <PercentNumberField source="introducerReward" />
        <EnumTextField
          source="inviteStatus"
          translationKeyPrefix="values.inviteStatus"
          label={translate('resources.invitations.fields.inviteStatus')}
        />
      </FlexibleListLayout>
    </List>
  );
};

interface ApiSelectInputProps {
  source: string;
  label: string;
}

const InvitableClientsSelectInput: React.FC<ApiSelectInputProps> = ({ source, label }) => {
  const [choices, setChoices] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dataProvider.getData(`invitable-clients`);
        const formattedChoices = data.map((item: any) => ({
          id: item.id,
          name: item.name
        }));
        setChoices(formattedChoices);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <SelectInput
      source={source}
      label={label}
      choices={choices}
      optionText="name"
      optionValue="id"
      validate={required()}
    />
  );
};

// SaveButton のカスタム版を作成して、バリデーション結果に基づいて無効化を制御し、メッセージ表示を追加
const CustomSaveButton = ({ isFormValid }: { isFormValid: boolean }) => {
  const notify = useNotify();

  return (
    <SaveButton
      label="招待メールを送信"
      icon={<SendIcon />}
      disabled={!isFormValid}
      type="button"
      mutationOptions={{
        onSuccess: () => {
          notify('招待メールを送信しました', { type: 'success', undoable: false }); // 成功時のメッセージ
        },
        onError: () => {
          notify('招待メールの送信に失敗しました。', { type: 'error' });
        }
      }}
    />
  );
};

const CustomToolbarCSV = () => <SaveButton label="CSVから招待メールを送信" icon={<SendIcon />} />;

const FormWatcher = () => {
  const { watch, setValue } = useFormContext();
  const clientId = watch('clientId');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (clientId) {
          const data = await dataProvider.getData(`clients/${clientId}/default-reward-setting`);
          setValue('partnerReward', data.partnerReward);
          setValue('introducerReward', '0');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [clientId, setValue]);

  return null;
};

const BorderStack = styled(Stack)(({ theme }) => ({
  padding: '24px',
  borderColor: alpha('#000000', 0.5),
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  width: '100%'
}));

export const InvitationCreate = () => {
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const notify = useNotify();
  const requiredFields = ['name', 'email', 'clientId', 'partnerReward', 'introducerReward']; // 必須フィールドのリスト

  const [isLoading, setIsLoading] = useState(false);

  const handleInputCSV = async (data: any) => {
    setIsLoading(true);
    try {
      const result = await dataProvider.bulkInvitations(data.file.rawFile);
      notify(`${result.length}件の招待メールを送信しました。`, { type: 'success', undoable: false });
    } catch (error) {
      handleError();
      return (error as any).errors;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title title="新規パートナー招待" />
      <CreateBase
        redirect="list" // 成功時にリストにリダイレクト
        mutationOptions={{
          onSuccess: () => {
            notify('招待メールを送信しました', { type: 'success', undoable: false }); // 成功時のカスタムメッセージ
          },
          onError: (e: any) => {
            if (e.body && !e.body.errors) {
              notify(e.message, { type: 'error' });
            } else {
              handleError();
            }
          }
        }}
      >
        <Box mt={3} mb={10}>
          <Stack spacing={3} sx={{ maxWidth: '1200px', margin: '0 auto' }}>
            <BorderStack>
              <Typography 
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold'
                }} 
                gutterBottom
              >
                一括招待メール送信
              </Typography>
              <Typography 
                  sx={{
                    fontSize: '14px'
                  }} 
                gutterBottom
              >
                招待者のCSVファイルを読み込んで招待メールを送信してください。
                <br />
                CSVファイルのテンプレートは<a href="/template.csv" style={{ color: '#9fb694' }}>こちら</a>からダウンロードしてください。
                <br />
                最大1000件まで一括送信可能です。
              </Typography>
              <SimpleForm 
                onSubmit={handleInputCSV} 
                toolbar={<CustomToolbarCSV />} 
                sx={{ 
                  p: 0, 
                  pb: { xs: '0px !important' } 
                }}
              >
                <FileUploadInput
                  sx={{ 
                    mt: 2, 
                    mb: 2,
                    '& .MuiFormHelperText-root': { whiteSpace: 'pre' },
                    '& .MuiButtonBase-root.MuiButton-root': { height: '36.5px' }
                  }}
                  label={false}
                  source="file"
                  accept={['.csv']}
                  validate={required()}
                ></FileUploadInput>
              </SimpleForm>
            </BorderStack>

            <BorderStack sx={{ paddingBottom: '0px' }}>
              <Typography 
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}  
                gutterBottom
              >
                個別招待メール送信
              </Typography>
              <Typography 
                sx={{
                  fontSize: '14px'
                }}
                gutterBottom
              >
                以下情報を入力し、新規パートナー希望者に招待メールを送信してください。
              </Typography>
              <SimpleForm
                toolbar={false}
                sx={{ 
                  maxWidth: '600px',
                  padding: '0px'
                }}
                defaultValues={{ partnerId: parseInt(getLocalStorageId() ?? '0') }}
              >
                <FormValidation requiredFields={requiredFields}>
                  {(isFormValid) => (
                    <>
                      <FormWatcher />
                      <TextInput source="name" fullWidth validate={required()} />
                      <TextInput source="email" fullWidth validate={required()} />
                      <TextInput source="partnerId" sx={{ display: 'none' }} />
                      <InvitableClientsSelectInput
                        label={translate('resources.affiliates.fields.client')}
                        source="clientId"
                      />
                      <PercentNumberInput source="partnerReward" validate={required()} />
                      <PercentNumberInput source="introducerReward" validate={required()} />
                      <CustomSaveButton isFormValid={isFormValid} />
                    </>
                  )}
                </FormValidation>
              </SimpleForm>
            </BorderStack>
          </Stack>
        </Box>
      </CreateBase>
    </>
  );
};
