import messages from '@bicstone/ra-language-japanese';

export const japaneseMessages = {
  ...messages,
  Role: '',
  resources: {
    accounts: {
      name: 'スタッフ管理',
      fields: {
        id: 'ID',
        name: '名前',
        email: 'メールアドレス',
        authority: '権限',
        accountInvalidFlag: 'アカウント有効フラグ',
        lastLoginDate: '最終利用日時',
        initialPassword: '初期パスワード'
      },
      actions: {
        edit: '編集'
      }
    },
    analytics: {
      name: {
        analytics: 'アナリティクス',
        all: '全体',
        clients: 'ECサイト別',
        partners: 'パートナー別'
      },
      fields: {
        expectedPaymentDate: '入金予定日',
        totalSalesAmount: 'Successfee経由総売上',
        totalSalesString: '総売上',
        currentReward: '現在の報酬額',
        paymentAmount: '入金予定額',
        totalFeebackCommission: 'Successfee手数料',
        totalPartnerCommission: 'パートナー報酬額',
        totalIntroducerCommission: '紹介報酬額',
        totalAffiliateCommission: 'アフィリエイト報酬額',
        totalSalesCount: '合計売上件数',
        totalClickCount: '合計訪問数',
        startDate: '開始日',
        endDate: '終了日',
        selectClient: 'ECサイト選択',
        selectPartner: 'パートナー選択',
        granularity: '粒度',
        graphTarget: 'グラフ対象選択',
        resultType: '内訳対象選択',
        date: '期間',
        details: '売上と件数の内訳',
        downloadCSV: 'CSVダウンロード',
        salesAmountRate: '売上割合',
        salesAmount: '売上高',
        feebackCommission: 'Successfee手数料',
        partnerCommission: 'パートナー報酬額',
        introducerCommission: '紹介報酬額',
        affiliateCommission: 'アフィリエイト報酬額',
        salesCount: '売上件数',
        clickCount: '訪問数'
      }
    },
    rewards: {
      name: '報酬管理',
      fields: {
        id: 'ID',
        rewardPeriod: '報酬発生期間',
        paymentDate: '入金日',
        administrativeFee: '振込事務手数料',
        rewardAmount: '報酬金額',
        salesTax: '消費税（10%）',
        totalAmount: '振込金額（税込）',
        status: 'ステータス'
      },
      detailName: '報酬管理 - 詳細'
    },
    invitations: {
      name: '二次パートナー招待',
      fields: {
        id: 'ID',
        inviteName: '被招待者',
        inviteEmail: 'メールアドレス',
        inviteStatus: 'ステータス',
        name: '被招待者名',
        email: 'メールアドレス',
        partnerReward: '報酬割合',
        introducerReward: '紹介報酬割合'
      }
    },
    affiliates: {
      name: 'PR用ツール',
      fields: {
        id: 'アフィリエイトID',
        postUrl: '使用WEBサイトURL',
        applyStatus: '申請状況',
        invalidFlag: 'ステータス',
        memo: '備考',
        client: 'ECストア名',
        affiliateId: 'アフィリエイトID',
        affiliateUrl: 'アフィリエイトURL',
        qrCode: 'QRコード',
        partnerReward: 'パートナー報酬',
        coupon: 'クーポンコード'
      },
      actions: {
        add: 'ECサイト追加'
      }
    },
    settings: {
      name: '設定',
      fields: {
        partnerType: 'アカウント種別',
        name: 'パートナー登録名',
        lastName: '姓',
        picLastName: 'ご担当者姓',
        firstName: '名',
        picFirstName: 'ご担当者名',
        email: 'メールアドレス ',
        tel: '電話番号',
        zipCode: '郵便番号',
        address: '住所',
        prefecture: '都道府県',
        city: '市区町村',
        address1: '番地',
        address2: 'ビル名 部屋番号',
        bankNumber: '銀行番号',
        bankName: '銀行名',
        bankBranchNumber: '支店番号',
        bankBranchName: '支店名',
        bankAccountType: '口座種別',
        bankAccountNumber: '口座番号',
        bankAccountName: '受取人名',
        invoiceNumber: 'インボイス登録番号',
        dunsNumber: 'D-U-N-S番号',
        website: 'Webサイト',
        frontImage: '身分証明書 表画像',
        backImage: '身分証明書 裏画像'
      }
    },
    registers: {
      name: {
        Terms: '利用規約',
        Contract: 'パートナー契約',
        Form: '登録情報入力',
        BasicInformationInput: '基本情報入力',
        FormPayment: '口座情報入力',
        Confirmation: '登録内容確認',
        ConfirmRegistrationDetails: '登録内容の確認',
        Completion: '登録申請完了',
        VerifyIdentity: '本人確認',
        Verification: '審査',
        ReviewApplicationCompleted: '審査申請完了',
        CreateStaff: 'パスワード登録',
        Upload: '身分証明書の選択',
        SMSAuth: 'SMS認証',
        Error: 'エラー'
      },
      fields: {
        partnerType: 'アカウント種別',
        name: '法人の名称',
        lastName: '姓',
        picLastName: 'ご担当者姓',
        firstName: '名',
        picFirstName: 'ご担当者名',
        zipCode: '郵便番号',
        address: '住所',
        prefecture: '都道府県',
        city: '市区町村',
        address1: '番地',
        address2: 'ビル名 部屋番号',
        address2Updated: '建物名、部屋番号など',
        tel: '電話番号',
        mobilePhone: '携帯電話番号',
        email: 'メールアドレス',
        emailConfirm: 'メールアドレス(確認)',
        bankNumber: '銀行コード',
        bankNumberUpdated: '銀行番号',
        bankName: '銀行名',
        bankBranchNumber: '支店コード',
        bankBranchNumberUpdated: '支店番号',
        bankBranchName: '支店名',
        bankAccountType: '口座種類',
        bankAccountNumber: '口座番号',
        bankAccountName: '口座名義',
        invoiceNumber: 'インボイス登録番号',
        dunsNumber: 'D-U-N-S番号',
        website: 'Webサイト',
        websiteUrl: 'WEBサイトURL',
        frontImage: '表面',
        backImage: '裏面',
        userName: 'ユーザー名',
        userEmail: 'メールアドレス(ログインID)',
        userPassword: 'パスワード',
        userPasswordConfirm: 'パスワード確認',
        confirmationCode: '認証番号(6桁)'
      },
      messages: {
        canNotChanged: '※登録後変更不可'
      }
    }
  },
  values: {
    authority: {
      1: '管理者',
      2: 'マネージャー',
      3: '経理'
    },
    partnerType: {
      1: '個人',
      2: '法人'
    },
    accountStatus: {
      0: '無効',
      1: '有効',
      2: '仮登録'
    },
    inviteStatus: {
      1: '未申請',
      2: '申請済み',
      3: '有効期限切れ',
      4: '申請中'
    },
    accountInvalidFlag: {
      0: '無効',
      1: '有効',
      2: '仮登録'
    },
    ecSiteType: {
      1: 'Shopify',
      99: 'その他'
    },
    bankAccountType: {
      1: '普通',
      2: '当座'
    },
    paymentMonth: {
      0: '当月',
      1: '翌月',
      2: '翌々月'
    },
    paymentDate: '%{day}日締め',
    paymentDateEndOfMonth: '月末締め',
    'apply-status': {
      1: '未承認',
      2: '承認済み',
      3: '却下'
    },
    invalidFlag: {
      0: '無効',
      1: '有効'
    },
    clientManagementType: {
      1: '全て',
      2: '個別管理'
    },
    depositStatus: {
      0: '未入金',
      1: '入金済み'
    },
    paymentStatus: {
      1: '支払いなし',
      2: '未振込',
      3: '振込済み',
      4: 'エラー'
    },
    resultTypes: {
      1: '全体',
      2: 'ECストア',
      3: 'パートナー'
      // 4: 'アフィリエイト'
    },
    graphTargetTypes: {
      salesAmount: 'Successfee経由総売上',
      feebackCommission: 'Successfee手数料',
      partnerCommission: 'パートナー報酬額',
      introducerCommission: '紹介報酬額',
      affiliateCommission: 'アフィリエイト報酬額',
      salesCount: '売上件数',
      clickCount: '訪問数'
    },
    graphTargetUnits: {
      salesAmount: '円',
      feebackCommission: '円',
      partnerCommission: '円',
      introducerCommission: '円',
      affiliateCommission: '円',
      salesCount: '件',
      clickCount: '回'
    }
  },
  cognito: {
    Unknown: 'サーバーとの通信に失敗しました。しばらくしてから再度お試しください。',
    MeEndpointException: 'ユーザーの取得に失敗しました。現在アカウントが利用できません。',
    NotAuthorizedException: 'ユーザー名もしくはパスワードが間違っています。',
    UserNotFoundException: 'ユーザーが見つかりません。',
    CodeMismatchException: '認証コードが間違っています。',
    UsernameExistsException: 'すでに同じユーザーが登録されています。',
    InvalidPasswordException:
      'パスワードは大文字、小文字、数字、英字をそれぞれ1文字以上含む8文字以上で設定してください。',
    ExpiredCodeException: '認証コードの有効期限が切れています。',
    LimitExceededException: 'リクエストが制限を超えました。しばらく時間をおいてから再度お試しください。'
  }
};
