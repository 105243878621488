import { Edit, required, SelectInput, SimpleForm, TextInput, useTranslate, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import { BANK_ACCOUNT_TYPE, PREFECTURE_LIST } from '../common_modules/constants/choices';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import SaveOnlyToolbar from '../common_modules/components/SaveOnlyToolbar';
import FormValidation from '../common_modules/components/FormValidation';
import {
  LabeledEnumTextField,
  LabeledTextField // LabeledTextField のインポートを追加
} from '../common_modules/components/LabeledFieldComponents';

// ConditionalField を SettingsEdit の中で定義
const ConditionalField = ({ source, label, helperText }: { source: string; label: string; helperText?: string }) => {
  const translate = useTranslate();
  const record = useRecordContext();

  if (!record) return null;
  if (source === 'name' && record.partnerType !== 2) return null;
  // partnerType が 2 の場合、別のラベルを使う
  const adjustedLabel =
    record.partnerType === 2
      ? source === 'lastName'
        ? translate('resources.settings.fields.picLastName')
        : source === 'firstName'
        ? translate('resources.settings.fields.picFirstName')
        : label
      : label;

  return <LabeledTextField source={source} label={adjustedLabel} helperText={helperText} />;
};

export const SettingsEdit = () => {
  const translate = useTranslate();
  const handleError = useErrorHandler();
  // 必須フィールドの定義
  const requiredFields = [
    'email',
    'tel',
    'zipCode',
    'prefecture',
    'city',
    'address1',
    'bankNumber',
    'bankName',
    'bankBranchNumber',
    'bankBranchName',
    'bankAccountType',
    'bankAccountNumber',
    'bankAccountName'
  ];

  return (
    <Edit
      title={translate('resources.settings.name')}
      redirect={false}
      mutationMode="pessimistic"
      mutationOptions={{ onError: handleError }}
    >
      <SimpleForm
        toolbar={
          <FormValidation requiredFields={requiredFields}>
            {(isFormValid) => <SaveOnlyToolbar disabled={!isFormValid} />}
          </FormValidation>
        }
        sx={{ 
          // maxWidth: '600px' 
          maxWidth: '100%'
        }}
      >
        <LabeledEnumTextField
          source="partnerType"
          translationKeyPrefix="values.partnerType"
          label={translate('resources.settings.fields.partnerType')}
        />
        <ConditionalField source="name" label={translate('resources.settings.fields.name')} />
        <ConditionalField source="lastName" label={translate('resources.settings.fields.lastName')} />
        <ConditionalField source="firstName" label={translate('resources.settings.fields.firstName')} />

        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <TextInput source="email" fullWidth validate={required()} />
        </Box>
        
        <TextInput source="tel" validate={required()} />
        <TextInput source="zipCode" validate={required()} />
        <SelectInput
          source="prefecture"
          label={translate('resources.settings.fields.prefecture')}
          choices={PREFECTURE_LIST}
          validate={required()}
        />
        <TextInput source="city" validate={required()} />

        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <TextInput source="address1" fullWidth validate={required()} />
        </Box>
        
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <TextInput source="address2" fullWidth helperText={false} />
        </Box>
        
        <Box
            sx={{
              width: '100%',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              margin: '40px 0px'
            }}
          >
        </Box>

        <TextInput source="bankNumber" validate={required()} helperText={'半角数字4桁'} />

        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <TextInput source="bankName" fullWidth validate={required()} />
        </Box>
        
        <TextInput source="bankBranchNumber" validate={required()} helperText={'半角数字3桁'} />

        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <TextInput source="bankBranchName" fullWidth validate={required()} helperText={false} />
        </Box>
        
        <NumericRadioButtonGroupInput source="bankAccountType" choices={BANK_ACCOUNT_TYPE} validate={required()}  helperText={false} />
        <TextInput source="bankAccountNumber" validate={required()} helperText={'半角数字7桁'} />
        
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <TextInput
            source="bankAccountName"
            fullWidth
            validate={required()}
            helperText={'カナ（小文字を除く）、英大文字、数字、半角スペース、記号（().-）のみ使用できます。'}
            FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }}
          />
        </Box>
        
        <TextInput source="invoiceNumber" helperText={'T+半角数字13桁'} />
        <ConditionalField source="dunsNumber" label={translate('resources.settings.fields.dunsNumber')} />
        
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <TextInput source="website" fullWidth />
        </Box>
        
      </SimpleForm>
    </Edit>
  );
};

export default SettingsEdit;
