import React, { useEffect, useState } from 'react';
import { Box, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { SimpleForm, TextInput, required, useTranslate, useNotify } from 'react-admin';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import NumericSelectRadioButtonGroupInput from '../common_modules/components/NumericSelectRadioButtonGroupInput';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import RegisterFormLayout from '../common_modules/components/RegisterFormLayout';
import RequiredLabel from '../common_modules/components/RequiredLabel';
import { BANK_ACCOUNT_TYPE } from '../common_modules/constants/choices';
import { getRegisterFormData, getRegisterToken, setRegisterFormData } from '../common_modules/components/Common';
import dataProvider from '../dataProvider';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import LinkButton from '../common_modules/components/LinkButton';
import { useFormContext } from 'react-hook-form';

interface RegisterFormData {
  partnerType?: number;
  [key: string]: any;
}

const CustomToolbar = ({ loading, isRedirectFromConfirm }: { loading: boolean, isRedirectFromConfirm: boolean }) => (
  <Stack alignItems="center" spacing={3} mt={5}>
    <RegisterSubmitButton type="submit" disabled={loading}>
      {/* 登録内容の確認へ */}
      {isRedirectFromConfirm ? '情報を更新する' : '登録内容の確認へ'}
    </RegisterSubmitButton>
    {/* <LinkButton href="/register/partner/contract">{'< 基本情報入力に戻る'}</LinkButton> */}
    <LinkButton href={isRedirectFromConfirm ? "/register/partner/confirm" : "/register/partner/form"}>
      {isRedirectFromConfirm ? '< 変更をキャンセルして戻る' : '< 基本情報入力に戻る'}
    </LinkButton>
  </Stack>
);

const FormContents: React.FC = () => {
  const translate = useTranslate();
  const [partnerType, setPartnerType] = useState<number | undefined>(undefined);
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const { getValues, setValue } = useFormContext();
  const notify = useNotify();

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      setPartnerType(formData.partnerType);
    }
  }, []);

  return (
    <Box 
      sx={{  
        width: '100%' 
      }}
    >
      <RequiredLabel label={translate('resources.registers.fields.bankNumberUpdated')} />
      <Box
        sx={{
          width: { xs: '200px', sm: '264px' }
        }}
      >
        <TextInput
          source="bankNumber"
          label={false}
          placeholder={translate('resources.registers.fields.bankNumberUpdated')}
          validate={required()}
          helperText={'半角数字4桁'}
        />
      </Box>
      
      <Box 
        sx={{
          marginTop: '14px'
        }}
      >
        <RequiredLabel label={translate('resources.registers.fields.bankName')} />
        <TextInput 
          source="bankName" 
          label={false}
          placeholder={translate('resources.registers.fields.bankName')} 
          validate={required()} 
        />
      </Box>
      
      <Box
        sx={{
          width: { xs: '200px', sm: '264px' },
          marginBottom: '14px'
        }}
      >
        <RequiredLabel label={translate('resources.registers.fields.bankBranchNumberUpdated')} />
        <TextInput
          source="bankBranchNumber"
          label={false}
          placeholder={translate('resources.registers.fields.bankBranchNumberUpdated')}
          validate={required()}
          helperText={'半角数字3桁'}
        />
      </Box>
      
      <RequiredLabel label={translate('resources.registers.fields.bankBranchName')} />
      <TextInput
        source="bankBranchName"
        label={false}
        placeholder={translate('resources.registers.fields.bankBranchName')}
        validate={required()}
      />

      <RequiredLabel label={translate('resources.registers.fields.bankAccountType')} />
      <NumericSelectRadioButtonGroupInput
        source="bankAccountType"
        choices={BANK_ACCOUNT_TYPE}
        // label={translate('resources.registers.fields.bankAccountType')}
        label={false}
        validate={required()}
      />

      <Box
        sx={{
          width: { xs: '200px', sm: '264px' },
          marginBottom: '14px'
        }}
      >
        <RequiredLabel label={translate('resources.registers.fields.bankAccountNumber')} />
        <TextInput
          source="bankAccountNumber"
          label={false}
          placeholder={translate('resources.registers.fields.bankAccountNumber')}
          validate={required()}
          fullWidth
          helperText={'半角数字7桁'}
        />
      </Box>
      
      <RequiredLabel label={translate('resources.registers.fields.bankAccountName')} />
      <TextInput
        source="bankAccountName"
        label={false}
        placeholder={translate('resources.registers.fields.bankAccountName')}
        validate={required()}
        helperText={'カナ（小文字を除く）、英大文字、数字、半角スペース、記号（().-）のみ使用できます'}
        FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }}
      />
    </Box>
  );
};

const FormPayment: React.FC = () => {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const [defaultValues, setDefaultValues] = useState<RegisterFormData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [partnerType, setPartnerType] = useState<number | undefined>(undefined);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isRedirectFromConfirm = searchParams.get('redirect-from-confirm') === 'true';

  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/partner/token-error', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      setDefaultValues(formData);
      setPartnerType(formData.partnerType);
    }
  }, []);

  const handleSubmit = async (data: RegisterFormData) => {
    setIsLoading(true);
    try {
      if (!data.address2) {
        data.address2 = '';
      }

      const sendingFormData = {
        token: getRegisterToken(),
        ...data,
        partnerType
      };

      const registerPartnerPaymentUrl = `/registers/partners/create-test-payment`;
      await dataProvider.register(sendingFormData, registerPartnerPaymentUrl);

      setRegisterFormData(sendingFormData);
      navigate(`/register/partner/confirm`);
      // if (data.partnerType === 1) {
      //   navigate(`/register/partner/upload-id`);
      // } else if (data.partnerType === 2) {
      //   navigate(`/register/partner/confirm`);
      // }
    } catch (error) {
      handleError();
      return (error as any).body.errors;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterFormLayout>
      <Container
        sx={{ 
          maxWidth: { sm: '552px', md: '772px' },
          marginTop: { xs: '20px', sm: '40px'},
          marginBottom: { xs: '20px', sm: '40px'},
          borderRadius: { sm: '24px', md: '24px' }, 
          border: { sm: '1px solid #EEEEEE', md: '1px solid #EEEEEE' },
          backgroundColor: '#FFFFFF'
        }}
      >
        <Stack 
          spacing={1}
          sx={{ 
            paddingLeft: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingRight: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingTop: { sm: '40px', md: '64px' },
            paddingBottom: { sm: '40px', md: '64px' }
          }}
        >
          <Helmet>
            <title>{translate('resources.registers.name.FormPayment')}</title>
          </Helmet>
          <RegisterHeaderProgress
            title={translate('resources.registers.name.FormPayment')}
            icon={<AccountBalanceOutlinedIcon />}
            step={3}
            totalSteps={7}
          />

          <Box display="flex" justifyContent="center">
            <Box 
              sx={{
                width: { xs: '288px', sm: '400px' },
                height: '20px',
              }}
              component="img" 
              src={`/images/stepper/img_stepper_4.svg`} 
              alt="stepper_4" 
            />
          </Box>

          <Box sx={{ marginBottom: '24px' }}>
            <Typography variant="body1" align="left">
              成果報酬を受け取るための口座情報を入力してください。
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                width: '100%',
              }}
            >
              <SimpleForm
                defaultValues={defaultValues}
                toolbar={<CustomToolbar loading={isLoading} isRedirectFromConfirm={isRedirectFromConfirm} />}
                sx={{
                  p: 0,
                  '& .MuiTextField-root': { width: '100%' },
                  paddingBottom: { xs: 0, sm: '0px !important' }
                }}
                onSubmit={handleSubmit}
                gap={3}
              >
                <FormContents />
              </SimpleForm>
            </Box>
          </Box>
        </Stack>
      </Container>
    </RegisterFormLayout>
  );
};

export default FormPayment;
