import React, { useEffect, useState } from 'react';
import { Box, Button, Link, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { SimpleForm, TextInput, required, useTranslate, SelectInput, useNotify } from 'react-admin';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import NumericSelectRadioButtonGroupInput from '../common_modules/components/NumericSelectRadioButtonGroupInput';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import RegisterFormLayout from '../common_modules/components/RegisterFormLayout';
import RequiredLabel from '../common_modules/components/RequiredLabel';
import { BANK_ACCOUNT_TYPE, PREFECTURE_LIST } from '../common_modules/constants/choices';
import { getRegisterFormData, getRegisterToken, setRegisterFormData } from '../common_modules/components/Common';
import dataProvider from '../dataProvider';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import LinkButton from '../common_modules/components/LinkButton';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import { useFormContext } from 'react-hook-form';

// const CustomDivider = styled(Divider)(({ theme }) => ({
//   borderColor: '#000000'
// }));

const AddressButton = styled(Button)(({ theme }) => ({
  width: '118px',
  height: '48px',
  borderRadius: '4px',
  marginTop: '7px',
  padding: '0px'
}));

interface RegisterFormData {
  partnerType?: number;
  [key: string]: any;
}

const CustomToolbar = ({ loading, isRedirectFromConfirm }: { loading: boolean, isRedirectFromConfirm: boolean }) => (
  <Stack alignItems="center" spacing={3} mt={5}>
    <RegisterSubmitButton type="submit" disabled={loading}>
      {/* 口座情報入力へ */}
      {isRedirectFromConfirm ? '情報を更新する' : '口座情報入力へ'}
    </RegisterSubmitButton>
    {/* <LinkButton href="/register/partner/contract">{'< パートナー契約確認に戻る'}</LinkButton> */}
    <LinkButton href={isRedirectFromConfirm ? "/register/partner/confirm" : "/register/partner/contract"}>
      {isRedirectFromConfirm ? '< 変更をキャンセルして戻る' : '< パートナー契約確認に戻る'}
    </LinkButton>
  </Stack>
);

const FormContents: React.FC = () => {
  const translate = useTranslate();
  const [partnerType, setPartnerType] = useState<number | undefined>(undefined);
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const { getValues, setValue } = useFormContext();
  const notify = useNotify();

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      setPartnerType(formData.partnerType);
    }
  }, []);

  const handleGetAddress = async () => {
    try {
      const { zipCode } = getValues();
      const url = `/registers/partners/address/${zipCode}`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;

      setValue('prefecture', data.prefecture);
      setValue('city', data.city);
      setValue('address1', data.address1);

      console.log('address=' + zipCode);
    } catch (e) {
      notify('住所の取得に失敗しました。', { type: 'error' });
    }
  };

  return (
    <Box 
      sx={{ 
        // maxWidth: '600px', 
        width: '100%'
      }}
    >
      {partnerType !== 1 && (
        <>
          <RequiredLabel label={translate('resources.registers.fields.name')} />
          <TextInput
            source="name"
            label={false}
            placeholder={translate('resources.registers.fields.name')}
            validate={required()}
            disabled={partnerType !== 2}
            helperText={'resources.registers.messages.canNotChanged'}
          />
        </>
      )}

      <Stack 
        direction="row" 
        // spacing={isSmallScreen ? 2 : 3} 
        spacing= {3}
        style={{ marginTop: '14px' }}
        sx={{
          width: '100%',
          gap: { sm:'16px', md: '24px'},
        }}
      >
        <Stack 
          direction="column"
          sx={{ width: '50%' }}
        >
          <RequiredLabel label={
              partnerType !== 1
                ? translate('resources.registers.fields.picLastName')
                : translate('resources.registers.fields.lastName')
            } 
          />
          <TextInput
            source="lastName"
            label={false}
            placeholder={
              partnerType !== 1
                ? translate('resources.registers.fields.picLastName')
                : translate('resources.registers.fields.lastName')
            }
            validate={required()}
            helperText={'resources.registers.messages.canNotChanged'}
          />
        </Stack>
        
        <Stack 
          direction="column"
          sx={{
            width: '50%',
            marginBottom: "24px"
          }}
        >
          <RequiredLabel label={
              partnerType !== 1
                ? translate('resources.registers.fields.picFirstName')
                : translate('resources.registers.fields.firstName')
            } 
          />
          <TextInput
            source="firstName"
            label={false}
            placeholder={
              partnerType !== 1
                ? translate('resources.registers.fields.picFirstName')
                : translate('resources.registers.fields.firstName')
            }
            validate={required()}
            helperText={'resources.registers.messages.canNotChanged'}
          />
        </Stack>
      </Stack>

      <RequiredLabel label={translate('resources.registers.fields.zipCode')}/>
      <Stack 
        direction="row" 
        spacing={isSmallScreen ? 2 : 3}
      >
        <Box 
          sx={{ 
            width: { xs: '200px', sm: '264px' } 
          }}
        >
          <TextInput
            source="zipCode"
            label={false}
            placeholder={translate('resources.registers.fields.zipCode')}
            validate={required()}
            // helperText={'ハイフンなし'}
          />
        </Box>
        <AddressButton 
          variant="contained" 
          color="primary" 
          onClick={handleGetAddress}
          sx={{ 
            width: { xs: '102px', sm: '112px' }
          }}
        >
          住所を入力
        </AddressButton>
      </Stack>
      
      <RequiredLabel label={translate('resources.registers.fields.prefecture')}/>
      <Box
        sx={{
          width: { xs: '200px', sm: '264px' }
        }}
      >
        <SelectInput
          label={false}
          source="prefecture"
          validate={required()}
          choices={PREFECTURE_LIST}
          InputProps={{
            notched: false
          }}
          // label={translate('resources.registers.fields.prefecture')}
        />
      </Box>
      

      <RequiredLabel label={translate('resources.registers.fields.city')}/>
      <TextInput 
        label={false}
        source="city" 
        placeholder={translate('resources.registers.fields.city')} 
        validate={required()} 
      />
      
      <RequiredLabel label={translate('resources.registers.fields.address1')}/>
      <TextInput 
        label={false}
        source="address1" 
        placeholder={translate('resources.registers.fields.address1')} 
        validate={required()} 
      />
      
      <RequiredLabel label={translate('resources.registers.fields.address2Updated')} isRequired={false}/>
      <TextInput 
        label={false}
        source="address2" 
        placeholder={translate('resources.registers.fields.address2Updated')} 
      />

      {partnerType === 2 && (
        <>
          <RequiredLabel label={translate('resources.registers.fields.tel')}/>
          <Box
            sx={{ 
              width: { xs: '200px', sm: '264px' }
            }}
          >
            <TextInput
              source="tel"
              label={false}
              placeholder={translate('resources.registers.fields.tel')}
              validate={partnerType === 2 ? required() : undefined}
              helperText={'ハイフンなし'}
            />
          </Box>
        </>
      )}
      {partnerType === 1 && (
        <>
          <RequiredLabel label={translate('resources.registers.fields.mobilePhone')}/>
          <Box
            sx={{ 
              width: { xs: '200px', sm: '264px' }
            }}
          >
            <TextInput
              source="mobilePhone"
              label={false}
              placeholder={translate('resources.registers.fields.mobilePhone')}
              validate={partnerType === 1 ? required() : undefined}
              helperText={'ハイフンなし'}
            />
          </Box>
        </>
      )}

      <Box sx={{ 
          marginTop: '14px'
        }}
      >
        <RequiredLabel label={translate('resources.registers.fields.email')}/>
        <TextInput 
          source="email" 
          label={false}
          placeholder={translate('resources.registers.fields.email')} 
          validate={required()} 
        />
      </Box>

      <Box 
        sx={{
          opacity: 0.5,
          backgroundColor: '#EEEEEE',
          marginBottom: '24px',
        }}
      >
        <Typography 
          align="left"
          sx={{
            padding: '16px',
            color: '#000000',
            fontSize:  '12px !important',
            fontWeight: 400,
            lineHeight: '150%',
            letterSpacing: '0%',
          }}
        >
          アカウント登録の際、キャリアメール（@docomo.ne.jp、@ezweb.ne.jp、@softbank.ne.jp など）をご利用いただくと、本人確認などの重要なメールが届かない、または受信制限によりブロックされる場合があります。
          <br />
          GmailやYahoo!メールなどのフリーメールのご利用を推奨いたします。
        </Typography>
      </Box>
      
      <RequiredLabel label={translate('resources.registers.fields.emailConfirm')}/>
      <TextInput
        source="emailConfirm"
        label={false}
        placeholder={translate('resources.registers.fields.emailConfirm')}
        validate={required()}
      />

      <RequiredLabel label={translate('resources.registers.fields.websiteUrl')} isRequired={false}/>
      <TextInput 
        source="website" 
        label={false}
        placeholder={translate('resources.registers.fields.websiteUrl')} 
      />
      
      <RequiredLabel label={translate('resources.registers.fields.invoiceNumber')} isRequired={false}/>
      <Box 
        sx={{
          width: { xs: '200px', sm: '264px' }
        }}
      >
        <TextInput
          source="invoiceNumber"
          label={false}
          placeholder={translate('resources.registers.fields.invoiceNumber')}
          helperText={'T+半角数字13桁'}
        />
      </Box>
      <Box 
        sx={{
          backgroundColor: '#EEEEEE',
          opacity: 0.5,
        }}
      >
        <Typography 
          align="left"
          sx={{
            padding: '16px',
            color: '#000000',
            fontSize:  '12px !important',
            fontWeight: 400,
            lineHeight: '150%',
            letterSpacing: '0%',
            marginBottom: '14px',
          }}
        >
          インボイス制度にご登録済みの方は、T+13桁のインボイス登録番号をご登録ください。(登録は必須ではございません。)
        </Typography>
      </Box>
      

      {partnerType !== 1 && (
        <>
          <RequiredLabel label={translate('resources.registers.fields.dunsNumber')} />
          <Box
            sx={{
              width: { xs: '200px', sm: '264px' }
            }}
          >
            <TextInput
              source="dunsNumber"
              label={false}
              placeholder={translate('resources.registers.fields.dunsNumber')}
              validate={required()}
              disabled={partnerType !== 2}
              helperText={
                <span>
                  D-U-N-Sナンバーの確認は
                  <Link href="https://duns-number-jp.tsr-net.co.jp/search/jpn/find_jpn.asp" target="_blank" rel="noopener">
                    こちら
                  </Link>
                  から。
                </span>
              }
            />
          </Box>
        </>
      )}
      {/* <CustomDivider sx={{ my: 2 }} />
      <TextInput
        source="bankNumber"
        label={translate('resources.registers.fields.bankNumber')}
        validate={required()}
        helperText={'半角数字4桁'}
      />
      <TextInput source="bankName" label={translate('resources.registers.fields.bankName')} validate={required()} />
      <TextInput
        source="bankBranchNumber"
        label={translate('resources.registers.fields.bankBranchNumber')}
        validate={required()}
        helperText={'半角数字3桁'}
      />
      <TextInput
        source="bankBranchName"
        label={translate('resources.registers.fields.bankBranchName')}
        validate={required()}
      />
      <NumericSelectRadioButtonGroupInput
        source="bankAccountType"
        choices={BANK_ACCOUNT_TYPE}
        label={translate('resources.registers.fields.bankAccountType')}
        validate={required()}
      />
      <TextInput
        source="bankAccountNumber"
        label={translate('resources.registers.fields.bankAccountNumber')}
        validate={required()}
        fullWidth
        helperText={'半角数字7桁'}
      />
      <TextInput
        source="bankAccountName"
        label={translate('resources.registers.fields.bankAccountName')}
        validate={required()}
        helperText={'カナ（小文字を除く）、英大文字、数字、半角スペース、記号（().-）のみ使用できます。'}
        FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }}
      /> */}
    </Box>
  );
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const [defaultValues, setDefaultValues] = useState<RegisterFormData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [partnerType, setPartnerType] = useState<number | undefined>(undefined);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isRedirectFromConfirm = searchParams.get('redirect-from-confirm') === 'true';

  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/partner/token-error', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      setDefaultValues(formData);
      setPartnerType(formData.partnerType);
    }
  }, []);

  const handleSubmit = async (data: RegisterFormData) => {
    setIsLoading(true);
    try {
      if (!data.address2) {
        data.address2 = '';
      }

      const sendingFormData = {
        token: getRegisterToken(),
        ...data,
        partnerType
      };

      // const registerPartnerUrl = `/registers/partners/create-test`;
      const registerPartnerBasicUrl = `/registers/partners/create-test-basic`;
      await dataProvider.register(sendingFormData, registerPartnerBasicUrl);

      setRegisterFormData(sendingFormData);
      // navigate(`/register/partner/confirm`);
      navigate(isRedirectFromConfirm ? `/register/partner/confirm` : `/register/partner/form-payment`);
      // if (data.partnerType === 1) {
      //   navigate(`/register/partner/upload-id`);
      // } else if (data.partnerType === 2) {
      //   navigate(`/register/partner/confirm`);
      // }
    } catch (error) {
      handleError();
      return (error as any).body.errors;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterFormLayout>
      <Container
        sx={{ 
          maxWidth: { sm: '552px', md: '772px' },
          marginTop: { xs: '20px', sm: '40px'},
          marginBottom: { xs: '20px', sm: '40px'},
          borderRadius: { sm: '24px', md: '24px' }, 
          border: { sm: '1px solid #EEEEEE', md: '1px solid #EEEEEE' },
          backgroundColor: '#FFFFFF'
        }}
      >
        <Stack 
          spacing={1}
          sx={{ 
            paddingLeft: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingRight: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingTop: { sm: '40px', md: '64px' },
            paddingBottom: { sm: '40px', md: '64px' }
          }}
        >
          <Helmet>
            <title>{translate('resources.registers.name.BasicInformationInput')}</title>
          </Helmet>
          <RegisterHeaderProgress
            title={translate('resources.registers.name.BasicInformationInput')}
            icon={<HandshakeOutlinedIcon />}
            step={3}
            totalSteps={7}
          />

          <Box display="flex" justifyContent="center">
            <Box 
              sx={{
                width: { xs: '288px', sm: '400px' },
                height: '20px'
              }}
              component="img" 
              src={`/images/stepper/img_stepper_3.svg`} 
              alt="stepper_3" 
            />
          </Box>

          {partnerType === 1 ? (
            <Box sx={{ marginBottom: '40px' }}>
              <Typography variant="body1" align="left">
                契約者の基本情報を入力してください。
                <br />
                携帯電話番号は本人確認に使用されます。利用可能なことをご確認の上入力してください。
                <br />
                お名前(姓/名)は登録後変更できませんので、ご注意ください。
              </Typography>
            </Box>
          ) : (
            <Box sx={{ marginBottom: '40px' }}>
              <Typography variant="body1" align="left">
                会社情報を入力してください。
                <br />
                メールアドレスは本人確認に使用されます。利用可能なことをご確認の上入力してください。
                <br />
                法人の名称、ご担当者姓/名は登録後変更できませんので、ご注意ください。
              </Typography>
            </Box>
          )}
          

          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                width: '100%',
                // maxWidth: '600px'
              }}
            >
              <SimpleForm
                defaultValues={defaultValues}
                toolbar={<CustomToolbar loading={isLoading} isRedirectFromConfirm={isRedirectFromConfirm} />}
                sx={{
                  p: 0,
                  '& .MuiTextField-root': { width: '100%' },
                  paddingBottom: { xs: 0, sm: '0px !important' }
                }}
                onSubmit={handleSubmit}
                gap={3}
              >
                <FormContents />
              </SimpleForm>
            </Box>
          </Box>
        </Stack>
      </Container>
    </RegisterFormLayout>
  );
};

export default Form;
